<template>

  <div>

    <!-- Filters -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5 class="mb-1">
          Transferência
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="mb-2"
          >
            <b-form-group
              label="PN"
              label-for="consultant"
            >
              <v-select
                id="consultant"
                v-model="consultant"
                :reduce="transf_consultants => transf_consultants.id"
                :options="originConsultants"
                :loading="loading.tranfConsultants"
                label="name"
                :class="getSelectErrorClass(v$.consultant.$error)"
              >
                <template slot="option" slot-scope="option">
                  {{ option.name }} ({{ option.role_name }})
                </template>
                <template slot="selected-option" slot-scope="option">
                  {{ option.name }} ({{ option.role_name }})
                </template>
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
              <div class="invalid-feedback">
                <span v-if="v$.consultant.required.$invalid">
                  Você deve informar um PN para transferência
                </span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="mb-2"
          >
            <b-form-group
              label="Novo PN responsável"
              label-for="newLeaderConsultant"
            >
              <v-select
                id="newLeaderConsultant"
                v-model="newLeaderConsultant"
                :reduce="consultant_list => consultant_list.id"
                :options="transferableConsultants"
                :loading="loading.consultants"
                label="name"
                :class="getSelectErrorClass(v$.newLeaderConsultant.$error)"
                :disabled="!consultant"
              >
                <template slot="option" slot-scope="option">
                  {{ option.name }} ({{ option.role_name }})
                </template>
                <template slot="selected-option" slot-scope="option">
                  {{ option.name }} ({{ option.role_name }})
                </template>
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
              <div class="invalid-feedback">
                <span v-if="v$.newLeaderConsultant.required.$invalid">
                  Você deve informar o novo PN responsável
                </span>
                <span v-else-if="v$.newLeaderConsultant.notEqual.$invalid">
                  O novo PN responsável não pode ser o PN selecionado para transferência
                </span>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="mb-3"
          >
            <b-button
              variant="primary"
              @click="save"
              :disabled="saving"
            >
              <b-spinner v-if="saving" small />
              {{ saving ? 'Aguarde...' : 'Transferir PN' }}
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5 class="mb-1">
          Histórico
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="mb-1"
          >
          <b-form-group
              label="PN"
              label-for="searchConsultant"
            >
              <v-select
                id="searchConsultant"
                v-model="searchConsultant"
                multiple
                :reduce="consultant_list => consultant_list.id"
                :options="searchConsultants"
                :disabled="searchFranchise.length > 0"
                :loading="loading.searchConsultants"
                label="name"
                @keyup.enter="search"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
          </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="mb-1"
          >
          <b-form-group
              label="Franqueado"
              label-for="searchTeam"
            >
              <v-select
                id="searchFranchise"
                multiple
                v-model="searchFranchise"
                :reduce="franchise_list => franchise_list.id"
                :options="franchises"
                :loading="loading.franchises"
                label="name"
                @input="searchConsultant = []"
                @keyup.enter="search"
              >
                <template #no-options="{ search, searching, loading }">
                  Sem resultados
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label class="mb-0">Exibir</label>
            <v-select v-model="itemsPerPage" dir="ltr" :options="itemsPerPageOptions" :clearable="false"
              class="per-page-selector d-inline-block mx-50" @input="search(currentPage)">
              <template #no-options="{ search, searching, loading }">
                Sem resultados
              </template>
            </v-select>
            <label class="mb-0">itens</label>
          </b-col>
          <b-col cols="12" md="8">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="outline-secondary"
                @click.prevent="clearSearch"
                class="mr-1"
                :disabled="loading.table"
              >
                Limpar filtros
              </b-button>
              <b-button
                variant="primary"
                @click.prevent="search(1)"
                :disabled="loading.table"
              >
                <b-spinner
                  v-if="loading.table"
                  small
                />
                <feather-icon
                  v-else
                  icon="SearchIcon"
                  class="mr-50"
                />
                <span>
                  {{ loading.table ? 'Aguarde...' : 'Pesquisar' }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        hover
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Não foram encontrados histórico de transferência"
        :busy="loading.table"

      >
      <!-- Column: date -->
      <template #cell(date)="data">
        <div class="text-wrap">
          <span class="align-text-top">{{ data.item.created_at | dateTime }}</span>
        </div>
      </template>

        <!-- Column: consultant -->
        <template #cell(consultant)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{ data.item.consultant.name }}</span>
          </div>
        </template>

        <!-- Column: old consultant -->
        <template #cell(old_consultant_leader)="data">
          <div class="text-wrap">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize" 
                v-if="data.item.old_leader_consultant"
              > 
                {{ data.item.old_leader_consultant.name }}
              </span>
            </div>
          </div>
        </template>

        <!-- Column: old franchise -->
        <template #cell(old_franchise)="data">
          <div class="text-wrap">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize" 
                v-if="data.item.old_leader_consultant"
              > 
                {{ data.item.old_leader_consultant.leader_franchise_name }}
              </span>
            </div>
          </div>
        </template>

        <!-- Column: new consultant -->
        <template #cell(new_consultant_leader)="data">
          <div class="text-wrap">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize" 
                v-if="data.item.new_leader_consultant"
              > 
                {{ data.item.new_leader_consultant.name }}
              </span>
            </div>
          </div>
        </template>

        <!-- Column: old franchise -->
        <template #cell(new_franchise)="data">
          <div class="text-wrap">
            <div class="text-wrap">
              <span class="align-text-top text-capitalize" 
                v-if="data.item.new_leader_consultant"
              > 
                {{ data.item.new_leader_consultant.leader_franchise_name }}
              </span>
            </div>
          </div>
        </template>

        <!-- Column: goal -->
        <template #cell(user)="data">
          <div class="text-wrap">
            <span class="align-text-top text-capitalize">{{ data.item.user.name }}</span>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Exibindo {{ pageItemsFrom }} à {{ pageItemsTo }} de {{ totalItems }} itens</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalItems" :per-page="itemsPerPage" first-number
              last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @change="search">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BCardHeader, BCardBody, BSpinner,
  BFormGroup, BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { getVueSelectErrorClass } from '@/helpers/validators'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import * as sharedTypes from '@/modules/shared/store/types'
import * as types from '../store/types'
import * as accountTypes from '@/modules/account/store/types'
import { APP_BUSINESS_PARTNER_ROLE_PNF,
         APP_BUSINESS_PARTNER_ROLE_EXPANSAO,
         APP_BUSINESS_PARTNER_ROLE_PNM1,
         APP_BUSINESS_PARTNER_ROLE_PNM2,
         APP_BUSINESS_PARTNER_ROLE_PNM3,
         APP_BUSINESS_PARTNER_ROLE_REGIONAL,
         APP_BUSINESS_PARTNER_ROLE_NACIONAL,
         APP_BUSINESS_PARTNER_ROLE_PNS,
         APP_BUSINESS_PARTNER_ROLE_FUTURO,
         APP_BUSINESS_PARTNER_ROLE_FF } from '@/constants/auth'
import { LEAD } from '@/constants/customers_types'


export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormGroup,
    BFormCheckbox,
    vSelect
  },
  setup() {
    return { toast: useToast(), v$: useVuelidate()  }
  },
  data() {
    return {
      loading: {
        tranfConsultants: false,
        consultants: false,
        franchises: false,
        searchConsultants: false,
        table: false
      },
      tableColumns: [
        { key: 'date', label: 'Data', sortable: true },
        { key: 'consultant', label: 'PN', sortable: true },
        { key: 'old_consultant_leader', label: 'Líder antigo', sortable: true },
        { key: 'old_leader_consultant.digital_franchise_owner.digital_franchise.name', label: 'Franquia antiga', sortable: true },
        { key: 'new_consultant_leader', label: 'Novo líder direto', sortable: true },
        { key: 'new_leader_consultant.digital_franchise_owner.digital_franchise.name', label: 'Nova franquia', sortable: true },
        { key: 'user', label: 'Usuário ', sortable: true },
      ],
      saving: false,
      consultant: undefined,
      newLeaderConsultant: undefined,
      searchConsultant: [],
      searchFranchise: [],
      currentPage: 1,
      itemsPerPage: 1,
    }
  },
  validations() {
    return {
      consultant: { required },
      newLeaderConsultant: { 
        required, 
        notEqual: (newConsultant) => {
          return this.consultant !== newConsultant
        }
       },
    }
  },
  computed: {
    ...mapGetters({
      transferableConsultants: types.TRANSFERABLE_CONSULTANTS,
      consultants: types.DIRECT_TEAM,
      transferredConsultants: types.TRANSFERRED_CONSULTANTS,
      initialItemsPerPage: sharedTypes.INITIAL_ITEMS_PER_PAGE,
      itemsPerPageOptions: sharedTypes.ITEMS_PER_PAGE_OPTIONS,
      searchConsultants: sharedTypes.CONSULTANTS,
      franchises: sharedTypes.FRANCHISES,
      user: accountTypes.USER
    }),
    items: function () {
      return this.transferredConsultants?.data || []
    },
    totalItems: function () {
      return this.transferredConsultants?.total || 0
    },
    pageItemsFrom: function () {
      return this.transferredConsultants?.from || 0
    },
    pageItemsTo: function () {
      return this.transferredConsultants?.to || 0
    },
    isLeaderConsultant: function () {
      return this.user?.user_role_id == APP_BUSINESS_PARTNER_ROLE_PNF
          || this.user?.user_role_id == APP_BUSINESS_PARTNER_ROLE_PNM1
          || this.user?.user_role_id == APP_BUSINESS_PARTNER_ROLE_PNM2
          || this.user?.user_role_id == APP_BUSINESS_PARTNER_ROLE_PNM3
          || this.user?.user_role_id == APP_BUSINESS_PARTNER_ROLE_PNS
          || this.user?.user_role_id == APP_BUSINESS_PARTNER_ROLE_FF
          || this.user?.user_role_id == APP_BUSINESS_PARTNER_ROLE_EXPANSAO
          || this.user?.user_role_id == APP_BUSINESS_PARTNER_ROLE_REGIONAL
          || this.user?.user_role_id == APP_BUSINESS_PARTNER_ROLE_NACIONAL
          || this.user?.user_role_id == APP_BUSINESS_PARTNER_ROLE_FUTURO
    },
    originConsultants: function () {
      return _.filter(this.transferableConsultants, (c) => c.id !== this.user?.appify_consultant_id)
    }
  },
  mounted() {
    this.itemsPerPage = this.initialItemsPerPage
    this.search()
    this.loading.tranfConsultants = true
    this.getTransferableConsultants()
      .catch(error => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Oops!',
            text: 'Ocorreu um erro ao carregar os consultores para seleção. Entre em contato com o setor de TI.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .finally(() => {
        this.loading.tranfConsultants = false
      })
    this.loading.searchConsultants
    this.getSearchConsultants()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os consultores para busca. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      })
      .finally(() => {
        this.loading.searchConsultants = false
      })
    this.loading.franchises = true
    this.getFranchises()
      .catch(() => {
        this.toast({
          component: ToastificationContent,
          props: {
            title: "Oops!",
            text: "Ocorreu um erro ao carregar os franqueados para busca. Entre em contato com o setor de TI.",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        })
      })
      .finally(() => {
        this.loading.franchises = false
      })
  },
  methods: {
    ...mapActions({ 
      getTransferableConsultants: types.GET_TRANSFERABLE_CONSULTANTS,
      getDirectTeam: types.GET_DIRECT_TEAM,
      getTransferredConsultants: types.GET_TRANSFERRED_CONSULTANTS,
      transferConsultant: types.TRANSFER_CONSULTANTS,
      getSearchConsultants: sharedTypes.GET_CONSULTANTS,
      getFranchises: sharedTypes.GET_FRANCHISES
    }),
    search(currentPage) {
      this.loading.table = true
      this.getTransferredConsultants({
        consultant: this.searchConsultant,
        franchise: this.searchFranchise,
        limit: this.itemsPerPage,
        currentPage: Number.isInteger(currentPage) ? currentPage : this.currentPage
      })
        .catch(error => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao carregar o histórico. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading.table = false
        })
    },
    getConsultantDirectTeam(){
      var payload;
      if(this.isLeaderConsultant){
        payload = this.user?.appify_consultant_id;
      }
      else if(this.consultant){
        payload = this.consultant;
      }
      if(!payload) return
      this.loading.consultants = true
      this.newLeaderConsultant = undefined
      this.getDirectTeam(payload)
        .catch(error => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Oops!',
              text: 'Ocorreu um erro ao carregar o novo PN responsável. Entre em contato com o setor de TI.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          this.loading.consultants = false
        })
    },
    async save(){
      const isFormCorrect = await this.v$.$validate()
      if (!isFormCorrect) return
      this.saving = true
      this.transferConsultant({
        consultant_id: this.consultant,
        new_leader_consultant_id: this.newLeaderConsultant,
      })
        .then((resp) => {
          this.toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso!',
              text: 'Transferência realizada com sucesso.',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
            this.toast({
              component: ToastificationContent,
              props: {
                title: 'Oops!',
                text: 'Ocorreu um erro ao realizar a transferência. Entre em contato com o setor de TI.',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            this.clear()
            this.search()
            this.saving = false
          })

    },
    getSelectErrorClass(thereIsError) {
      return getVueSelectErrorClass(thereIsError);
    },
    clear(){
      this.consultant = undefined
      this.newLeaderConsultant = undefined
      this.v$.$reset()
    },
    clearSearch(){
      this.searchConsultant = []
      this.searchFranchise = []
      this.search(1)
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
